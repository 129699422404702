
/**
 * scss/vendor
 * ----------------------------------------------------------
 * SASS vendor environment, include dependencies...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @author     Pieter-Jan Arts      <pj.arts@uselab.com>
 * @copyright  2015 Uselab.com
 *
 */

/* vendor
------------------------------------------------------------ */
@import 'theme/fonts/_opensans.scss';
@import 'vendor/vendor';

