
/**
 * Roboto
 * ----------------------------------------------------------
 * File contains all the Roboto font configurations...
 *
 * @type       Sass
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  2017 Uselab.com
 *
 */

@import url('//fonts.googleapis.com/css?family=Open+Sans:400,600,700');

