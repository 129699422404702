
/**
 * vendor/slick-slider
 * ----------------------------------------------------------
 *
 * @type       Sass
 * @author     PJ Arts
 * @copyright  2015 Uselab.com
 *
 */

$slick-loader-path  : "slick-carousel/slick/";
$slick-font-path    : "slick-carousel/slick/fonts/";

// slick CSS and theme
@import "slick-carousel/slick/slick.scss";
@import "slick-carousel/slick/slick-theme.scss";

// -----------------------------------------------------------------------------------------
// overrule some styles to make bootstrap columns work
// in Slick Slider
// -----------------------------------------------------------------------------------------

.slick-dots {
    left:0;  /* IOS9 fix (ipad check) */
}

.slick-slider.column-slider {

    .slick-list {

        padding:0!important; /* overrule inline styles */

        .slick-track {
            
            display: flex;

            .slick-slide {

                height:auto; /* IOS9 fix (ipad check) */
                display: flex;

                .column,
                > div {
                    display: flex;
                }

                .block-image-text {
                    display: flex;
                    flex-direction: column;
                }

                .footer {
                    margin-top: auto;
                    justify-content: flex-end;
                }
            }
        }
    }
}

.slick-slide > div .column {
    display: flex!important;
}

.slick-list {
    .slick-loading & {
        background: none;
    }
}
